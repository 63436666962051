.marquee_container {
  overflow: hidden;
  width: 283px;
  background-color: #1ebce3;
  position: relative;
  cursor: pointer;
  height: 24px;
}

.marquee_text {
  white-space: nowrap;
  color: #000;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
  animation: marquee 15s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
