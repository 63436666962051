// STYLES FOR HEADER PART
.headerPart {
  background: #fff;
  width: 980px;
}
.first_header_child {
  box-sizing: border-box;
  padding: 10px 0px 12px 20px;
}
.second_header_child {
  background-color: #186591;
  border-radius: 0px 0px 0px 10px;
  box-sizing: border-box;
  padding: 12px 12px 12px 24px;
  max-width: 300px;
  height: 68px;
}
.main_header_text {
  font-size: 28px;
  color: #186591;
  font-weight: bold;
}
.sub_header_text {
  font-size: 18px;
  color: #000;
  font-weight: bold;
}
.helpdesk_text {
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  line-height: 18px;
}
.boldText {
  font-size: 14px;
}

// STYLES FOR BODY PART
.body_part {
  width: 980px;
  background-color: #fff;
  box-sizing: border-box;
  padding-bottom: 20px;
}

.announcement_part {
  width: 60%;
  padding-right: 12px;
  border-left: 1px #ccc solid;
  margin-bottom: 20px;
}
.announcement_header {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 42px;
  float: left;
  font-size: 17px;
  line-height: 42px;
  background-color: #e6e6e6;
  border: 1px #e0dcdc solid;
  border-left: 0px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.067);
  color: #186591;
}
.announcement_box {
  box-sizing: border-box;
  padding: 12px;
  font-size: 14px;
  line-height: 21px;
  border-bottom: 1px dashed #ccc;
  height: fit-content;
  color: #186591;
  // background-color: #faf8f8;
  background: linear-gradient(270deg, #fff 0%, #eceaea 100%);
}

// STYLES FOR NAVBAR PART
.navbar_container {
  width: 998px;
  height: 41px;
  background-color: #fff;
  &__Navbar {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    padding-right: 10px;
    background-color: #186591;
    color: #fff;
    float: left;
    &__links {
      text-decoration: none;
      line-height: 30px;
      font-size: 13px;
      padding: 0px 20px;
      color: #fff;
      cursor: pointer;
      &:hover {
        background-color: #4bb2ed;
        color: #000;
      }
    }
  }
  &__curvedPart {
    width: 100%;
    min-height: 11px;
  }
}
// RESPONCIVE DESIGN
@media screen and (max-width: 1024px) {
  .headerPart {
    width: 850px;
  }
  .body_part {
    width: 850px;
  }
  .navbar_container {
    width: 868px;
  }
}

@media screen and (max-width: 900px) {
  .headerPart {
    width: 650px;
  }
  .body_part {
    width: 650px;
  }
  .navbar_container {
    width: 668px;
  }
}

@media screen and (max-width: 666px) {
  //FOR HEADER PART
  .headerPart {
    width: 95vw;
  }
  .main_header_text {
    font-size: 12px;
  }
  .sub_header_text {
    font-size: 10px;
  }
  .helpdesk_text {
    font-size: 9px;
    line-height: 12px;
  }
  .boldText {
    font-size: 10px;
  }

  //FOR BODY PART
  .body_part {
    width: 95vw;
  }
  .announcement_part {
    width: 50%;
  }
  .announcement_header {
    font-size: 13px;
    line-height: 14px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .announcement_box {
    font-size: 10px;
  }

  //FOR NAVBAR
  .navbar_container {
    width: 100vw;
    &__Navbar {
      &__links {
        font-size: 10px;
      }
    }
  }
}
