.main_container {
  width: 100%;
  height: 150vh;
  background-color: #fff;
}
.top_div {
  width: 100%;
  height: 28px;
  background-color: #094561;
  z-index: 9999;
  border-bottom: 1px solid #04323d;
}
.header_div {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 40px;
  border-bottom: 3px solid #1ebce3;
  box-shadow: 0 1px 3px 2px rgba(177, 177, 177, 0.5);
  min-height: 80px;
}
.helpdesk {
  max-width: 320px;
  &__text {
    text-align: right;
    font-size: 14px;
    line-height: 20px;
    color: #777777;
  }
}

.animation_div {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding: 0px 40px;
  background: #fff;
  position: sticky;
  top: -30px;
  z-index: 1000;
  &__container {
    width: 80%;
    font-size: 12px;
    box-sizing: border-box;
    padding-left: 20px;
    background: #1ebce3;
    height: 50px;
    box-shadow: 0 2px 1px 2px rgba(177, 177, 177, 0.5);
    border-radius: 0px 0px 10px 10px;
  }
}
.form_div {
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0px 40px;
  &__container {
    width: 80%;
    box-sizing: border-box;
    padding: 20px 25px;
    background-color: #f4f4f4;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    &__text {
      width: 60%;
      color: #5d676c;
      font-weight: 100;
      font-size: 25px;
      line-height: 30px;
      text-rendering: optimizelegibility;
    }
  }
}

.bottom_div {
  margin-top: auto;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 0px 40px;
  width: 100%;
  height: 50px;
  background: linear-gradient(180deg, #158bc2 0%, #094561 100%);
  &__container {
    width: 80%;
    color: #fff;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
  }
}

@media screen and (max-width: 666px) {
  .header_div {
    padding: 10px 10px;
  }
  .form_div {
    width: 100vw;
    overflow-x: hidden;
    &__container {
      width: 95vw;
      &__text {
        width: 80%;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  .bottom_div {
    padding: 0px 10px;
    &__container {
      font-size: 10px;
      line-height: 14px;
    }
  }
  .animation_div {
    padding: 0px 5px;
    &__container {
      width: 95vw;
    }
  }
}
