.marquee_container {
  overflow: hidden;
  width: 100%;
  background-color: #d3d4d3; // Equivalent to bg-gray-800// Equivalent to p-4
  position: relative;
  cursor: pointer;
  height: 24px;
}

.marquee_text {
  white-space: nowrap;
  font-size: 12px;
  line-height: 25px;
  font-weight: 700;
  display: flex; // Try flex instead of inline-block
  animation: marquee 35s linear infinite;
  &.paused {
    animation-play-state: paused;
  }
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
