.container {
  width: 100vw;
  padding: 40px;
}
.main_container {
  background-color: transparent;
  width: 1000px;
  min-height: 90vh;
  height: auto;
  padding-bottom: 12px;
}
.bottom_div {
  background-color: #fff;
  width: 980px;
  height: 14px;
}

@media screen and (max-width: 1024px) {
  .main_container {
    width: 870px;
  }
  .bottom_div {
    width: 850px;
  }
}

@media screen and (max-width: 900px) {
  .container {
    padding: 10px 0px;
    overflow-x: hidden;
  }
  .main_container {
    width: 670px;
  }
  .bottom_div {
    width: 650px;
  }
}

@media screen and (max-width: 666px) {
  .container {
    padding: 10px 0px;
    overflow-x: hidden;
  }
  .main_container {
    width: 100vw;
  }
  .bottom_div {
    width: 95vw;
  }
}
