.news_div {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 18px 18px 8px;
  border-bottom: 1px dashed #b3b1b1;
  min-height: 14px;
  height: fit-content;
  // background-color: #faf8f8;
  background: linear-gradient(180deg, #fff 0%, #eceaea 100%);
}
.newsdiv_text {
  font-size: 16px;
  color: #000;
}
.click_text {
  color: red;
  cursor: pointer;
  font-size: 13px;
  width: fit-content;
  &:hover {
    font-weight: bold;
    color: red;
  }
}

@media screen and (max-width: 666px) {
  .newsdiv_text {
    font-size: 10px;
    text-overflow: ellipsis;
  }
  .click_text {
    font-size: 10px;
  }
}
