.custom_button {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid #dbddbd;
  text-align: left;
  height: 37px;
  // background-color: #faf8f8;
  background: linear-gradient(180deg, #fff 0%, #eceaea 100%);
  border-bottom: 2px solid #dbddbd;
  border-radius: 0px 10px 0px 0px;
  box-sizing: border-box;
}
.button_text {
  box-sizing: border-box;
  // padding: 0px 0px 0px 25px;
  font-size: 16px;
  line-height: 35px;
  color: #186591;
}

@media screen and (max-width: 666px) {
  .button_text {
    font-size: 12px;
    line-height: 14px;
  }
}
