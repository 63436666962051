.result_container {
  width: 60%;
  padding-right: 12px;
}

.header_text {
  color: #186591;
  font-size: 16px;
  font-weight: bold;
}

.bottom_text {
  color: #000;
  font-weight: 700;
  font-size: 12px;
}

.table_container {
  width: 100%;
  border: 1px solid #000;
}

.result_table {
  width: 100%;
  border-collapse: collapse;
  font-size: 18px;
  font-weight: 21px;

  td {
    border: 1px solid black;
    padding: 10px;

    input {
      width: 60%;
      padding: 5px;
      height: 20px;
      border: 1px solid #ccc;
      outline: none;

      &:hover {
        background-color: rgb(212, 229, 240);
      }

      &:focus {
        border: 2px solid;
        border-color: #535454;
        border-radius: 4px;
      }
    }
  }
}

.showTable_wrapper {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}

.show_table {
  width: 100%;
  border-collapse: collapse;

  th {
    border: 1px solid black;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    padding: 10px;
  }

  td {
    border: 1px solid black;
    font-size: 12px;
    line-height: 14px;
    padding: 10px;
    font-weight: 400;
  }
}

.submit_button {
  width: 184px;
  padding: 10px 25px;
  background-color: blue;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 12px;
}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 900px) {}

@media screen and (max-width: 666px) {
  .result_container {
    width: 50%;
  }

  .header_text {
    font-size: 12px;
  }

  .result_table {
    font-size: 14px;
  }
}