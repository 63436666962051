.admitform_div {
  width: 100%;
  background: #fff;
}
.form_header {
  width: 100%;
  box-sizing: border-box;
  padding-left: 12px;
  height: 50px;
  background: linear-gradient(180deg, #158bc2 0%, #094561 100%);
  font-weight: bold;
  color: #fff;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}
.form_container {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  padding: 0px 12px 12px 12px;
  &__text {
    color: #000;
    white-space: nowrap;
    font-weight: bold;
    font-size: 12px;
    line-height: 25px;
  }
  &__input {
    width: 368px;
  }
}
.submit_button {
  width: 70px;
  height: 30px;
  box-sizing: border-box;
  padding: 4px 14px;
  //   background: #faa732;
  background: linear-gradient(180deg, #efc282 0%, #faa732 100%);
  border: 1px solid #e39830;
  color: #fff;
  font-weight: 500;
  border-radius: 4px;
}
