.admin_container {
  width: 100%;
  padding-bottom: 20px;
}
.card_styles {
  width: 40%;
  box-sizing: border-box;
  padding: 20px;
  font-size: 14px;
  line-height: 21px;
  background: #fff;
  color: #000;
  border-radius: 10px;
}
.submit_button {
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  padding: 4px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #efc282 0%, #faa732 100%);
  border: 1px solid #e39830;
  color: #fff;
  font-weight: 500;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: 1000px) {
  .card_styles {
    width: 60%;
  }
}
@media screen and (max-width: 600px) {
  .card_styles {
    width: 90%;
  }
}
